import { EventEmitter, NgModule, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { ToastComponent } from './toast.component';
import { IconModule } from '../icon/icon.module';
import { ToastDangerModule } from '../toast-danger/toast-danger.module';
import { ToastConfig } from '@shared/types/toast.type';
import { ToastService } from '@services/toast.service';

@NgModule({
  declarations: [ToastComponent],
  imports: [IconModule, NgIf, ToastDangerModule],
  exports: [ToastComponent],
})
export class ToastModule {
  @Output() reloadAction = new EventEmitter<void>();

  private readonly defaultTimeout = 5 * 1000; // 5 segundos;
  private _timeoutId?: ReturnType<typeof setTimeout>;

  toast: ToastConfig = {
    type: 'SUCESS',
    message: '',
  };

  isOpened = false;

  constructor(private readonly _toastService: ToastService) {
    this._toastService.watch.subscribe(config => {
      clearTimeout(this._timeoutId);

      this.toast = config;
      this.isOpened = true;

      this._timeoutId = setTimeout(
        () => (this.isOpened = false),
        config.timeout || this.defaultTimeout,
      );
    });
  }

  closeToast() {
    clearTimeout(this._timeoutId);
    this.isOpened = false;
  }

  reloadPage() {
    if (this.toast.reloadAction) this.toast.reloadAction();
    this.closeToast();
  }
}
