import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '@services/local-storage.service';
import { UserManagerService } from '@services/user-manager.service';
import { LocalStorageKeysEnum } from '@shared/enums/local-storage.enum';

export const loggedUser: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);

  if (isLogged()) return true;

  router.navigate(['login'], { queryParams: { redirectTo: state.url } });
  localStorageService.clear();

  return false;
};

export const unLoggedUser: CanActivateFn = () => {
  const router = inject(Router);

  if (!isLogged()) return true;

  router.navigate(['configuracoes']);

  return false;
};

function isLogged() {
  const userService = inject(UserManagerService);
  const localStorageService = inject(LocalStorageService);

  try {
    if (userService.user) return true;

    const accessToken = localStorageService.get(
      LocalStorageKeysEnum.ACCESS_TOKEN,
    );

    if (!accessToken) return false;

    return true;
  } catch (err) {
    return false;
  }
}
