import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@environment';
import { LocalStorageService } from '@services/local-storage.service';
import { LocalStorageKeysEnum } from '@shared/enums/local-storage.enum';

export const AuthTokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const localStorageService = inject(LocalStorageService);
  const authToken = localStorageService.get(LocalStorageKeysEnum.ACCESS_TOKEN);
  const isApi = req.url.includes(environment.apiUrl);
  const pathsWithoutAuthToken = ['login'];

  let transformedReq: HttpRequest<unknown> = req;

  if (
    isApi &&
    !!authToken &&
    mustHaveAuthToken(req.url, pathsWithoutAuthToken)
  ) {
    transformedReq = req.clone({
      setHeaders: {
        authorization: `Bearer ${authToken}`,
      },
    });
  }

  return next(transformedReq);
};

function mustHaveAuthToken(path: string, pathsWithoutAuthToken: string[]) {
  for (const pathWithoutAuthToken of pathsWithoutAuthToken) {
    if (path.includes(pathWithoutAuthToken)) return false;
  }

  return true;
}

